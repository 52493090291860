import { useState } from "react";
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  CircularProgress,
  createStyles,
  FormGroup,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { InputField } from "src/components/formik_material_inputs";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      "& .MuiAlert-root": {
        marginTop: "1em",
      },
    },
    paper: {
      position: "absolute",
      width: 420,
      borderRadius: 10,
      padding: theme.spacing(4, 6, 2),
      outline: 0,
    },
    form_group: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default function FMPModal(props) {
  const classes = useStyles();
  const { email } = formFields;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [fmp_error, setFMPError] = useState("");
  const [success, setSuccess] = useState(false);

  const fmpSubmit = async (values) => {
    setFMPError("");
    const { email } = values;
    const recaptcha_response = await executeRecaptcha("fmp_page");

    const resp = await fetch("/api/auth/forgot_password.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, recaptcha_response }),
    });
    const json = await resp.json();

    if (json.success) {
      setSuccess(true);
    } else {
      setFMPError(json.reason);
    }
  };

  return (
    <Modal {...(props as any)} className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <Typography variant="h6" align="center">
          Forgot My Password
        </Typography>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationScheme}
          onSubmit={fmpSubmit}
        >
          {({ isSubmitting, values }) => (
            <>
              {!success && (
                <Form id="fmp-form">
                  <FormGroup className={classes.form_group}>
                    <InputField
                      fullWidth
                      autoFocus
                      type="email"
                      autoComplete="email username"
                      {...email}
                    />

                    {fmp_error && <Alert severity="error">{fmp_error}</Alert>}

                    <Button
                      style={{ marginTop: "1em" }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Reset my Password
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          style={{ position: "absolute" }}
                        />
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              )}
              {success && (
                <Alert severity="success">
                  Check your e-mail (<strong>{values.email}</strong>) for a
                  password reset link.
                </Alert>
              )}
            </>
          )}
        </Formik>
      </Paper>
    </Modal>
  );
}

const formFields = {
  email: {
    name: "email",
    label: "Email Address",
    initial_value: "",
    helperText: "The email address you signed up with",
    test: Yup.string()
      .required("Email address is required")
      .email("A valid email address is required"),
  },
};

export function validationScheme() {
  return Yup.object().shape(
    Object.entries(formFields).reduce((acc, v) => {
      acc[v[0]] = v[1].test;
      return acc;
    }, {})
  );
}
