import { useQuery } from "react-query";

export function userStatusQuery() {
  return useQuery("user-status", async () => {
    const resp = await fetch("/api/auth/user.json");
    if (!resp.ok) throw new Error(`Couldn't get user status!`);
    const json = await resp.json();
    // if (!json.success) throw new Error(`Server error on fetching user status`);
    return json;
  });
}
