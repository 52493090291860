import { useState } from "react";
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  FormGroup,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { InputField } from "src/components/formik_material_inputs";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Alert from "@material-ui/lab/Alert";
import { Link } from "gatsby-theme-material-ui";
import { navigateToApp } from "src/redirect_to_app";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      "& .MuiAlert-root": {
        marginTop: "1em",
      },
    },
    paper: {
      position: "absolute",
      width: 400,
      borderRadius: 10,
      padding: theme.spacing(4, 6, 2),
      outline: 0,
    },
    form_group: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default function LoginModal({
  refresh_status,
  show_fmp,
  redir_on_login = true,
  ...props
}) {
  const classes = useStyles();
  const { email, password } = formFields;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [login_error, setLoginError] = useState("");

  const loginSubmit = async (values) => {
    setLoginError("");
    const { email, password } = values;
    const recaptcha_response = await executeRecaptcha("login_page");

    const resp = await fetch("/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, recaptcha_response }),
    });
    const json = await resp.json();

    if (json.success) {
      await refresh_status();
      if (redir_on_login) {
        navigateToApp();
      }

      return props.onClose();
    } else {
      setLoginError(json.reason);
    }
  };

  return (
    <Modal {...(props as any)} className={classes.root}>
      <Paper className={classes.paper} elevation={2}>
        <Typography variant="h6" align="center">
          Login
        </Typography>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationScheme}
          onSubmit={loginSubmit}
        >
          {({ isSubmitting }) => (
            <>
              <Form id="login-form">
                <FormGroup className={classes.form_group}>
                  <InputField
                    fullWidth
                    autoFocus
                    type="email"
                    autoComplete="email username"
                    {...email}
                  />
                  <InputField
                    fullWidth
                    type="password"
                    autoComplete="current-password"
                    {...password}
                  />

                  {login_error && <Alert severity="error">{login_error}</Alert>}

                  <Button
                    style={{ marginTop: "1em" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Login
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{ position: "absolute" }}
                      />
                    )}
                  </Button>
                  <Button
                    style={{ marginTop: "1em" }}
                    size="small"
                    variant="text"
                    onClick={show_fmp}
                  >
                    Forgot your password
                  </Button>
                  <Divider
                    style={{
                      margin: "1em 0em",
                      borderColor: "#0000000a",
                      borderWidth: "thin",
                      width: "100%",
                    }}
                  />
                  <span>
                    Don't have an account?{" "}
                    <Button component={Link} href="/signup/" color="primary">
                      Signup
                    </Button>
                  </span>
                </FormGroup>
              </Form>
            </>
          )}
        </Formik>
      </Paper>
    </Modal>
  );
}

const formFields = {
  email: {
    name: "email",
    label: "Email Address",
    initial_value: "",
    // helperText: "Use your organizational email for a smoother process",
    test: Yup.string()
      .required("Email address is required")
      .email("A valid email address is required"),
  },
  password: {
    name: "password",
    label: "Password",
    initial_value: "",
    // helperText: "Full name is preferable",
    test: Yup.string().required("Password is required"),
  },
};

export function validationScheme() {
  return Yup.object().shape(
    Object.entries(formFields).reduce((acc, v) => {
      acc[v[0]] = v[1].test;
      return acc;
    }, {})
  );
}
